import { FC, MouseEventHandler, MutableRefObject } from 'react';
import classnames from 'classnames';

import { ThumbDownOutlinedSize16, ThumbUpOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import Button from 'bloko/blocks/button';
import Text, { TextSize } from 'bloko/blocks/text';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import { useNotification } from 'src/components/Notifications/Provider';
import { ArticleRating, VoteType } from 'src/models/articleRating';
import { formatNumberToTenthsDigit, sendVote } from 'src/pages/Article/components/Footer/DefaultFooter/Rating/utils';

import styles from './vote-button.less';

export type VoteButtonType = VoteType.Dislike | VoteType.Like;

export const VOTE_BUTTONS: VoteButtonType[] = [VoteType.Like, VoteType.Dislike];

export interface VoteButtonProps {
    articleId: string;
    buttonType: VoteButtonType;
    ratingState: ArticleRating;
    setRatingState: (articleRating: ArticleRating) => void;
    onClick?: MouseEventHandler;
    innerRef?: MutableRefObject<null>;
}

const VoteButton: FC<VoteButtonProps> = ({ articleId, buttonType, ratingState, setRatingState, onClick, innerRef }) => {
    const { addNotification } = useNotification();

    const { voteState, likesCount } = ratingState;

    const likeButton = buttonType === VoteType.Like;
    const pressed = voteState === buttonType;
    const voted = voteState !== VoteType.NoVoted;

    const voteStateUpdated = !voted || (voted && !pressed) ? buttonType : VoteType.NoVoted;

    let likesCountUpdated = likesCount;
    if (voteStateUpdated === VoteType.Like) {
        likesCountUpdated += 1;
    } else if (voteState === VoteType.Like) {
        likesCountUpdated -= 1;
    }

    const clickHandler =
        onClick ||
        (() => {
            setRatingState({ voteState: voteStateUpdated, likesCount: likesCountUpdated });
            sendVote(articleId, voteStateUpdated, (error) => {
                setRatingState({ voteState, likesCount });
                defaultRequestErrorHandler(error, addNotification);
            });
        });

    return (
        <Button
            className={classnames(styles.voteButton, {
                [styles.voteButtonPressed]: pressed,
            })}
            icon={
                <BlokoIconReplaceContainer>
                    {likeButton ? <ThumbUpOutlinedSize16 /> : <ThumbDownOutlinedSize16 />}
                </BlokoIconReplaceContainer>
            }
            pressed={pressed}
            onClick={clickHandler}
            ref={innerRef}
        >
            {likeButton && likesCount > 0 && (
                <span className={styles.voteButtonText}>
                    <Text Element="span" size={TextSize.Small} strong>
                        {formatNumberToTenthsDigit(likesCount)}
                    </Text>
                </span>
            )}
        </Button>
    );
};
export default VoteButton;
