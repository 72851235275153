interface CompanyParams {
    id: number;
    department?: { '@code': string };
}

type GetCompanyLinkFunction = (company: CompanyParams) => string;

export const getCompanyLink: GetCompanyLinkFunction = ({ id, department }) => {
    const link = `/employer/${id}`;
    return department ? `${link}?dpt=${department['@code']}` : link;
};
