import { createContext } from 'react';

interface CutData {
    value: boolean;
    toggleValue: () => void;
}

const CutContext = createContext<CutData>({
    value: false,
    toggleValue() {
        this.value = !this.value;
    },
});

export default CutContext;
