import { FC } from 'react';

import { H2 } from 'bloko/blocks/header';
import Text, { TextImportance } from 'bloko/blocks/text';

import { Participant } from 'src/models/insiderArticleSpecificData';
import LandingTextContainer from 'src/pages/Article/components/common/LandingTextContainer';

interface InsiderLandingParticipantProps {
    participant: Participant;
}

const InsiderLandingParticipant: FC<InsiderLandingParticipantProps> = ({ participant }) => {
    const { name, description, jobTitle, bigPicture } = participant;
    return (
        <LandingTextContainer>
            <div className="landing-insider">
                <div className="landing-insider__header">
                    <H2>{name}</H2>
                    <div className="landing-insider__job-title">
                        <Text importance={TextImportance.Tertiary}>{jobTitle}</Text>
                    </div>
                </div>
                <img loading="lazy" src={bigPicture} className="landing-insider__image" alt={name} />
                <div className="landing-insider__description">{description}</div>
            </div>
        </LandingTextContainer>
    );
};

export default InsiderLandingParticipant;
