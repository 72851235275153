import { FC } from 'react';

import { VSpacing } from '@hh.ru/magritte-ui';
import Column from 'bloko/blocks/column';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';

import { useSelector } from 'src/hooks/useSelector';
import { Image } from 'src/models/articlePage';
import ArticleLead from 'src/pages/Article/components/ContentHeader/common/IntroductionParagraph/ArticleLead';

import styles from 'src/pages/Article/components/ContentHeader/DefaultContentHeader/LeadWithAuthor/lead-with-author.less';

interface LeadWithAuthorProps {
    photo: Image;
}

const LeadWithAuthor: FC<LeadWithAuthorProps> = ({ photo }) => {
    const { authorName, authorPost } = useSelector(({ articlePage }) => articlePage);

    return (
        <div className={styles.leadWithImage}>
            <Column xs="4" s="6" m="7" l="8">
                <ArticleLead />
            </Column>
            <Column xs="4" s="2" m="2" l="2">
                <div className={styles.author}>
                    <img loading="lazy" className={styles.authorImage} src={photo.url} alt={authorName} />
                    <div>
                        <Text size={TextSize.Small} strong>
                            {authorName}
                        </Text>
                        <VSpacing default={4} />
                        <Text size={TextSize.Small} importance={TextImportance.Tertiary}>
                            {authorPost}
                        </Text>
                    </div>
                </div>
            </Column>
        </div>
    );
};

export default LeadWithAuthor;
