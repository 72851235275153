import { useContext, useRef } from 'react';
import { domToReact } from 'html-react-parser';

import BlokoLink, { LinkAppearance } from 'bloko/blocks/link';

import { TagMapperComponent } from 'src/hooks/useParseHtml';

import CutContext from 'src/pages/Article/components/Content/BlogArticle/mappers/Cut/CutContext';
import { CutProps } from 'src/pages/Article/components/Content/BlogArticle/mappers/Cut/types';

const CutSwitcher: TagMapperComponent<CutProps> = ({ options, originalElement: { children } }) => {
    const { toggleValue } = useContext(CutContext);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const handleClick = () => {
        buttonRef.current?.blur();
        toggleValue();
    };
    if (!children) {
        return null;
    }
    return (
        <BlokoLink appearance={LinkAppearance.Pseudo} onClick={handleClick} ref={buttonRef}>
            {domToReact(children, options)}
        </BlokoLink>
    );
};

export default CutSwitcher;
