import { useContext } from 'react';
import { domToReact } from 'html-react-parser';

import VSpacing from 'bloko/blocks/vSpacing';

import { TagMapperComponent } from 'src/hooks/useParseHtml';

import CutContext from 'src/pages/Article/components/Content/BlogArticle/mappers/Cut/CutContext';
import { CutProps } from 'src/pages/Article/components/Content/BlogArticle/mappers/Cut/types';

const CutContent: TagMapperComponent<CutProps> = ({ options, originalElement: { children } }) => {
    const { value } = useContext(CutContext);
    if (!children || !value) {
        return null;
    }
    return (
        <>
            <VSpacing base={3} />
            {domToReact(children, options)}
        </>
    );
};

export default CutContent;
