import { useSelector } from 'src/hooks/useSelector';
import { ArticleTypes } from 'src/models/articlePage/types';

const useIsIntroductionParagraph: () => boolean = () => {
    const { lead, announce, type } = useSelector(({ articlePage }) => articlePage);

    return type === ArticleTypes.PressRelease ? !!announce : !!lead;
};

export default useIsIntroductionParagraph;
