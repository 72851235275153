import { FC } from 'react';

import Text, { TextSize, TextImportance } from 'bloko/blocks/text';

import { Participant } from 'src/models/insiderArticleSpecificData';

interface InsiderSimpleParticipantInfoProps {
    participant: Participant;
}

const InsiderSimpleParticipantInfo: FC<InsiderSimpleParticipantInfoProps> = ({ participant }) => {
    const { name, description, jobTitle, bigPicture } = participant;
    return (
        <>
            <div className="interview-dropdown__image-wrapper">
                <img loading="lazy" src={bigPicture} className="interview-dropdown__image" alt={name} />
                <div className="interview-dropdown__name">{name}</div>
                <Text size={TextSize.Small} importance={TextImportance.Secondary}>
                    {jobTitle}
                </Text>
            </div>
            <div className="interview-dropdown__description">{description}</div>
        </>
    );
};

export default InsiderSimpleParticipantInfo;
