import { FC } from 'react';
import { DomElement } from 'domhandler';
import parse, { HTMLReactParserOptions } from 'html-react-parser';

import { useSelector } from 'src/hooks/useSelector';

import Cut from 'src/pages/Article/components/Content/BlogArticle/mappers/Cut';

import styles from './style.less';

interface Props {
    landingDesign: boolean;
}

const ParsedContent: FC<Props> = ({ landingDesign }) => {
    const content = useSelector((state) => state.articlePage.content);

    const options: HTMLReactParserOptions = {
        replace: (originalElement: DomElement) => {
            if (originalElement.attribs?.['data-cut']) {
                return <Cut options={options} originalElement={originalElement} landingDesign={landingDesign} />;
            }
            return null;
        },
    };

    if (!content) {
        return null;
    }

    return <div className={styles.cmsContent}>{parse(content, options)}</div>;
};

export default ParsedContent;
