import { useSelector } from 'src/hooks/useSelector';
import { Image, ImageType } from 'src/models/articlePage';

type PageHeaderHook = (isInsider?: boolean) => {
    image: Image | undefined;
    publicationTime: string | undefined;
    title: string | undefined;
};

const usePageHeader: PageHeaderHook = (isInsider?: boolean) => {
    const title = useSelector((state) => state.articlePage?.title);
    const publicationTime = useSelector((state) => state.articlePage?.publicationTime);
    const images = useSelector((state) => state.articlePage?.images);

    const image = images?.find((img) => {
        return isInsider ? img.type === ImageType.Landing : img.type === ImageType.Title;
    });

    return { title, publicationTime, image };
};

export default usePageHeader;
