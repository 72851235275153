import { FC } from 'react';

import { EyeOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import HSpacing from 'bloko/blocks/hSpacing';
import numberFormatter from 'bloko/common/numberFormatter';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';

import styles from './article-views-count.less';

interface ArticleViewsCountProps {
    count: number;
}

const ArticleViewsCount: FC<ArticleViewsCountProps> = ({ count }) => (
    <div className={styles.container}>
        {numberFormatter.format(String(count))}
        <HSpacing base={1} />
        <BlokoIconReplaceContainer>
            <EyeOutlinedSize16 />
        </BlokoIconReplaceContainer>
    </div>
);

export default ArticleViewsCount;
