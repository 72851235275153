import { FC, ReactNode } from 'react';

import styles from './introduction-paragraph.less';

interface IntroductionParagraphProps {
    content: ReactNode;
    small?: boolean;
}

const IntroductionParagraph: FC<IntroductionParagraphProps> = ({ content, small = false }) => {
    if (!content) {
        return null;
    }

    return <p className={small ? styles.leadSmall : styles.lead}>{content}</p>;
};

export default IntroductionParagraph;
