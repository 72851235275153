import { FC } from 'react';

import useParseHtml, { createTagMapper } from 'src/hooks/useParseHtml';
import { useSelector } from 'src/hooks/useSelector';

import LinkMapper from 'src/pages/Article/components/ContentHeader/common/IntroductionParagraph/LinkMapper';
import IntroductionParagraph from 'src/pages/Article/components/ContentHeader/common/IntroductionParagraph/index';

interface ArticleLeadProps {
    small?: boolean;
}

const ArticleLead: FC<ArticleLeadProps> = ({ small = false }) => {
    const { lead } = useSelector(({ articlePage }) => articlePage);

    const parseHtml = useParseHtml({ a: createTagMapper(LinkMapper, {}) });

    return <IntroductionParagraph content={parseHtml(lead) || null} small={small} />;
};

export default ArticleLead;
