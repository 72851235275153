import { FC } from 'react';

import { useSelector } from 'src/hooks/useSelector';

import IntroductionParagraph from 'src/pages/Article/components/ContentHeader/common/IntroductionParagraph/index';

const ArticleAnnounce: FC = () => {
    const { announce } = useSelector(({ articlePage }) => articlePage);

    return <IntroductionParagraph content={announce} />;
};

export default ArticleAnnounce;
