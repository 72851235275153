import Text, { TextSize, TextImportance } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    text: 'article.pressRelease.footer',
};

const PressReleaseFooter: TranslatedComponent = ({ trls }) => (
    <Text size={TextSize.Large} importance={TextImportance.Tertiary}>
        {trls[TrlKeys.text]}
    </Text>
);

export default translation(PressReleaseFooter);
