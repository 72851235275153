import classnames from 'classnames';
import { domToReact } from 'html-react-parser';

import { TagMapperComponent } from 'src/hooks/useParseHtml';
import useToggleState from 'src/hooks/useToggleState';

import CutContext from 'src/pages/Article/components/Content/BlogArticle/mappers/Cut/CutContext';
import { CutProps } from 'src/pages/Article/components/Content/BlogArticle/mappers/Cut/types';

import styles from './style.less';

const CutWrapper: TagMapperComponent<CutProps> = ({ options, originalElement: { children }, landingDesign }) => {
    const [expanded, toggleExpanded] = useToggleState(false);
    const cutState = {
        value: expanded,
        toggleValue: toggleExpanded,
    };
    if (!children) {
        return null;
    }
    return (
        <div
            className={classnames(styles.cmsCut, {
                [styles.cmsCutLanding]: landingDesign,
            })}
        >
            <CutContext.Provider value={cutState}>{domToReact(children, options)}</CutContext.Provider>
        </div>
    );
};

export default CutWrapper;
