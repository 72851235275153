import { TagMapperComponent } from 'src/hooks/useParseHtml';

import CutContent from 'src/pages/Article/components/Content/BlogArticle/mappers/Cut/CutContent';
import CutSwitcher from 'src/pages/Article/components/Content/BlogArticle/mappers/Cut/CutSwitcher';
import CutWrapper from 'src/pages/Article/components/Content/BlogArticle/mappers/Cut/CutWrapper';
import { CutProps } from 'src/pages/Article/components/Content/BlogArticle/mappers/Cut/types';

const Cut: TagMapperComponent<CutProps> = (props) => {
    const dataCut = props.originalElement.attribs?.['data-cut'];
    if (dataCut === 'cut') {
        return <CutWrapper {...props} />;
    }
    if (dataCut === 'cut-content') {
        return <CutContent {...props} />;
    }
    if (dataCut === 'cut-switcher') {
        return <CutSwitcher {...props} />;
    }
    return null;
};

export default Cut;
